<template>
  <div>    
    <b-row>
      <b-col cols="12" v-if="active_role == 'corporate'">
        <log-event-corporate></log-event-corporate>
      </b-col>
      <b-col cols="12" v-else>
        <log-event-superadmin></log-event-superadmin>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol, BButton
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import LogEventCorporate from './event-table/LogEventCorporate.vue'
  import LogEventSuperadmin from './event-table/LogEventSuperadmin.vue'

  export default {
    components: {
      BRow, BCol, BButton,
      LogEventCorporate, LogEventSuperadmin
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        active_role: null
      }
    },
		created() {
      this.checkAuth()
    },
		mounted() {
	    this.active_role = localStorage.getItem('role')
		},
    methods: {
      gotoAddEvent() {
        this.$router.push({ path: '/event/add' })
      },
    }
  }
</script>